import React from "react";
import "./product_docs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import edit from "./spkl_edit_docs.svg";
import editmore from "./spkl_editmore_docs.svg";
import editbtn from "./spkl_update_docs.svg";
import inventory from "./spkl_inventory_docs.svg";
import "./update.css";

const Update = () => {
  return (
    <div className="update-container">
      <div id="update" className="container border secondary rounded">
        <ul className="list-group">
          <h1 className="text-center title">Update Product</h1>

          <hr />
          <li>
            <div className="image-container">
              <p className="p">
                1. To edit a product, click on the{" "}
                <FontAwesomeIcon icon={faPenToSquare} /> below the edit column.
              </p>
              <img src={edit} alt="Edit Product" className="centered-image" />
              <p className="p">
                2. Change anything you’d like. You can add up to a total of 5
                product images.
              </p>
              <img
                src={editmore}
                alt="Edit More Product"
                className="centered-image"
              />
              <p className="p">
                3. Click the Update button to update your product.
              </p>
              <img
                src={editbtn}
                alt="Update Product"
                className="centered-image"
              />
              <p className="p">
                4. The updates will be reflected in your Inventory Management.
              </p>
              <img
                src={inventory}
                alt="Inventory Update"
                className="centered-image"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Update;
