import React from "react";
import "./product_docs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import inv from "./spkl_inv_docs.svg";
import emptyinv from "./spkl_emptiynv_docs.svg";
import "./delete.css";
const Delete = () => {
  return (
    <div className="delete-container">
      <div id="delete" className="container border secondary rounded">
        <ul className="list-group">
          <h1 className="text-center title">Delete Product</h1>

          <hr />
          <li>
            <div className="image-container">
              <p className="p">
                1. To delete a product, click on the{" "}
                <FontAwesomeIcon icon={faTrash} /> below the delete column.
              </p>
              <img
                src={inv}
                alt="Inventory Before Deletion"
                className="centered-image"
              />
              <p className="p">
                2. The changes will be reflected across your Inventory
                Management and the store’s online presence.
              </p>
              <img
                src={emptyinv}
                alt="Empty Inventory"
                className="centered-image"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Delete;
