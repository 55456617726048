import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported

const Home = () => {
  return (
    <div className="container mt-3">
      <h2 className="text-center mb-4">Documentation:</h2>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {" "}
        {/* Adjusted for responsiveness */}
        {/* Add Button Card */}
        <div className="col">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Add Product</h5>
              <p className="card-text">Add a new product to your inventory.</p>
              <Link to="/add" className="btn btn-primary">
                Add
              </Link>
            </div>
          </div>
        </div>
        {/* Update Button Card */}
        <div className="col">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Update Product</h5>
              <p className="card-text">Update or existing product details.</p>
              <Link to="/update" className="btn btn-warning">
                Update
              </Link>
            </div>
          </div>
        </div>
        {/* Delete Button Card */}
        <div className="col">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Delete Product</h5>
              <p className="card-text">Remove a product from your inventory.</p>
              <Link to="/delete" className="btn btn-danger">
                Delete
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
