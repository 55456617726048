import React from "react";
import logo from "./spkl_dark_logo.svg"; // Adjust the path to where your logo is stored
import "./nav.css"; // Assuming you will use a separate CSS file for styling

const Nav = () => {
  return (
    <nav className="navbar">
      <div className="navbar-container edge">
        {/* Logo in the middle of the screen */}
        <div
          className="logo-container"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <img src={logo} alt="Logo" className="navbar-logo" />
          <h1>Docs</h1>
        </div>

        {/* Link to spockal.com on the right */}
        <div className="link-container edge">
          <a
            href="https://spockal.com"
            target="_blank"
            rel="noopener noreferrer"
            className="marketplace-button" // Added class for styling
          >
            Marketplace
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
