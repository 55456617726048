import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Nav from "./nav.js"; // Adjust the path as needed
import Add from "./add.js"; // Ensure this path matches your file structure
import Update from "./update.js"; // Ensure this path matches your file structure
import Delete from "./delete.js"; // Ensure this path matches your file structure
import Home from "./home.js";

function App() {
  return (
    <Router>
      <div className="App">
        <Nav /> {/* This includes the navbar in your app */}
        <Routes>
          <Route path="/" element={<Home />} />{" "}
          {/* Add the Home component for the default path */}
          <Route path="/add" element={<Add />} />
          <Route path="/update" element={<Update />} />
          <Route path="/delete" element={<Delete />} />
          {/* If you have a Home or Store_docs component, add its Route here */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
