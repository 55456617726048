import React from "react";
import "./product_docs.css";
import login from "./spkl_login_docs.svg";
import addprodbtn from "./spkl_addproduct_docs.svg";
import addbtn from "./spkl_addp_docs.svg";
import inv from "./spkl_inv_docs.svg";
import "./add.css";
const Add = () => {
  return (
    <div className="add-container">
      <div id="add" className="container border secondary rounded">
        <ul className="list-group">
          <h1 className="text-center title">Add Product</h1>
          <hr />
          <li>
            <div className="image-container">
              <p className="p">1. Navigate to Spockal Login</p>
              <p className="p">
                2. Log into or create a store to own your products
              </p>
              <img src={login} alt="Spockal Login" className="centered-image" />
              <p className="p">
                3. Navigate to the Add Product tab on the left-hand side of your
                screen
              </p>
              <img
                src={addprodbtn}
                alt="Navigate to Add Product"
                className="centered-image"
              />
              <p className="p">
                4. Add the title, description, price, and image. Enable quantity
                tracking if you would like to have an automatic shutoff. And
                click the add product button.
              </p>
              <img
                src={addbtn}
                alt="Add Product Details"
                className="centered-image"
              />
              <p className="p">
                5. View your products in your Inventory Management (IMS).
              </p>
              <img
                src={inv}
                alt="Inventory Management"
                className="centered-image"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Add;
